<template>

  <div>

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'config-settings-add' }"
              >
                <span class="text-nowrap">Agregar Setting</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="settingItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.id }}</span>
            </div>
          </b-media>
        </template>

        <!-- Column: Code -->
        <template #cell(code)="data">
          <b-media vertical-align="center">
            {{ data.item.code }}
          </b-media>
        </template>

        <!-- Column: Key -->
        <template #cell(key)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.key }}</span>
          </div>
        </template>

        <!-- Column: Serialized -->
        <template #cell(serialized)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.serialized)}`"
            class="text-capitalize"
          >
            {{ data.item.serialized }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="ml-1 cursor-pointer"
              @click="$router.push({ name: 'config-settings-edit', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Editar"
              :target="`invoice-row-${data.item.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="ml-1 cursor-pointer text-danger"
              @click="deleteSetting(data.item.id)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-delete-icon`"
            />
          </div>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} items</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './SettingsListFilters.vue'
import userStoreModule from './userStoreModule'
import useUsersList from './useSettingList'
import UserListAddNew from './SettingListAddNew.vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data() {
    return {
      settingItems: []
    }
  },
  created() {
    this.fetchSettings()
  },
  setup() {

    const isAddNewUserSidebarActive = ref(false)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

     // Register module
     if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'Json', value: 1 },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      serializedOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    deleteSetting(id) {

      this.$bvModal
        .msgBoxConfirm('Por favor confirma antes de eliminar cualquier item', {
          title: '¿Estás seguro?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value) {
            console.log('id: ', id)
            userStoreModule.actions.deleteSetting([], { id }).then((response) => {
            console.log(response)
            this.$toast({
                  component: ToastificationContentVue,
                  position: 'top-right',
                  props: {
                    title: `Advertencia`,
                    icon: 'TrashIcon',
                    variant: 'warning',
                    text: response.data.message,
                  },
                })
                this.fetchSettings()
            }).catch((error) =>  {
              console.log(error.response.status, error.response)
              this.$toast({
                  component: ToastificationContentVue,
                  position: 'top-right',
                  props: {
                    title: `Error` + ' (' + error.response.status + ')',
                    icon: 'TrashIcon',
                    variant: 'danger',
                    text: error.response.data.message,
                  },
                })
            })
          }
        })

      

    },
    fetchSettings() {
      store.dispatch('app-user/fetchUsers')
      .then(response => { 
        console.log('fetchUsers', response.data)
        this.settingItems = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.settingItems = undefined
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
